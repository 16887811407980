{
  "version": "0.10.15",
  "license": "MIT",
  "main": "dist/index.cjs.js",
  "module": "dist/index.js",
  "typings": "dist/index.d.ts",
  "files": [
    "dist",
    "src"
  ],
  "engines": {
    "node": ">=12"
  },
  "exports": {
    ".": {
      "require": "./dist/index.cjs.js",
      "import": "./dist/index.js",
      "default": "./dist/index.js"
    }
  },
  "scripts": {
    "prestart": "rm -rf dist && yarn types:build",
    "start": "concurrently \"yarn dev\" \"yarn types\"",
    "dev": "node ../../scripts/dev",
    "build:only": "node ../../scripts/build",
    "build": "yarn build:only && yarn types:build",
    "types": "tsc -w",
    "types:build": "tsc -p tsconfig.json",
    "format": "prettier --write src/**/*.ts",
    "test": "jest --maxWorkers=1",
    "test:watch": "jest --watch",
    "test:coverage": "jest --coverage",
    "lint": "eslint -c ../../.eslintrc .",
    "lint:fix": "yarn lint --fix",
    "prepare": "yarn build",
    "size": "size-limit",
    "analyze": "size-limit --why",
    "docs": "rm -rf ./docs && typedoc && rm -f ./docs/README.md && mkdir ./docs/home &&mv ./docs/modules.md ./docs/home/content.md && node ../../scripts/docs-store && npx prettier --write './docs/**/*'"
  },
  "name": "@100mslive/hms-video-store",
  "author": "100ms",
  "sideEffects": false,
  "dependencies": {
    "@100mslive/hms-video": "0.9.15",
    "eventemitter2": "^6.4.7",
    "immer": "^9.0.6",
    "reselect": "4.0.0",
    "zustand": "3.5.7"
  },
  "devDependencies": {
    "ts-node": "^10.4.0",
    "tslib": "^2.2.0"
  },
  "description": "This is an addon to the core sdk provided by 100ms. It abstracts away the intricacies of data management and provides a flux based reactive data store where data flows in only one direction.",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/100mslive/hms-video-store.git"
  },
  "keywords": [
    "video",
    "webrtc",
    "conferencing",
    "100ms"
  ],
  "bugs": {
    "url": "https://github.com/100mslive/hms-video-store/issues"
  },
  "homepage": "https://github.com/100mslive/hms-video-store#readme",
  "gitHead": "0578f113babef7488a788837c63f7f8a7aa246e3"
}
